<template>
  <div class="container-fluid">
    <div id="modalLabel" class="box-title">{{ $t('message.termsandconditions') }}</div>
    <div class="divider"/>
    <div class="box-body">
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>ACCEPTANCE OF TERMS.</span>This Agreement provides the general terms and conditions (the "Terms of Use") applicable to all services (the "Services") offered by Beef to China ("BTC"), to you through its Web site located at www.beeftochina.com.cn (the "Site"). By accessing or using this Site, you accept without limitations or qualifications to be bound by the Terms of Use. BTC reserves the right to change the Terms of Use from time to time at its sole discretion. Your use of the Site will be subject to the most current version of the Terms of Use posted on the Site at the time of such use. In addition, when using particular Services or the material posted on this Site ("Materials"), or entering sections of the Site reserved strictly for users of BTC, you shall be subject to any terms and conditions set out in policies of BTC posted on the Site, including, without limitation, the Privacy Policy and other posted guidelines or rules applicable to such Services or Materials that may contain terms and conditions in addition to those in this Agreement. All such policies, guidelines or rules are hereby incorporated by reference into this Agreement. If you breach any of the Terms of Use, BTC may, in addition to terminating your access to use this Site, limit your activity, immediately remove your offers, warn other users of your actions, issue a warning, temporarily suspend, indefinitely suspend or terminate your subscription and refuse to provide services to you. This Agreement is void where prohibited by law, and the right to access the Site and Materials is revoked in such jurisdictions. BTC maintains this Site for your use and grants you the permission and limited access to use and view the Site subject to these Terms of Use.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>REGISTRATION AND USER INFORMATION.</span>Certain portions of the Site are only available to registered users of BTC as described in these Terms of Use ("Users") The services or materials available to Users may include services provided by BTC and by third parties ("Associates"). To become a User of BTC you must submit a complete BTC registration form. By submitting a registration form, you represent and warrant (a) that you are 18 years of age or over; and (b) that you are signing up as a User on behalf of an company you have identified in the registration form and are authorized to sign for and bind that company; (c) the information on the registration form is current, complete, and accurate; and (d) you are not a competitor or working for a competitor of BTC. User must update registration information to ensure that it continues to be current, complete, and accurate. BTC reserves the right to accept or reject User registrations.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>USE AND PROTECTION OF USERNAME AND PASSWORD.</span>You are responsible for maintaining the confidentiality of your account Username(s) and password(s). You are responsible for all uses of your account, whether or not actually or expressly authorized by you. If there is a breach of the confidentiality of a password(s) or Username(s) assigned to you, or any breach of security through your BTC account, then you shall notify BTC immediately in writing and send the information at account@beeftochina.com.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>LIMITED COPYRIGHT LICENSE.</span>The Materials and Services on this Site, including, but not limited to, the compilation of all content, are protected by copyright and/or other intellectual property laws and any unauthorized use of the Materials or Services at this Site may violate such laws. Except as expressly provided herein, BTC and its suppliers do not grant any express or implied right to you under any patents, copyrights, trademarks, rights in trade secrets, or other intellectual property rights with respect to the Materials and Services. Except as expressly permitted herein, no portion of the Materials on this Site may be reproduced in any form or by any means without the prior written permission of BTC.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>USE OF USER INFORMATION.</span>In order to make various features and functions of Services and Materials available to Users, BTC may collect information about a User and a User's use of BTC ("User Information"). You acknowledge and agree that BTC's collection and use of your User Information as set forth in BTC's Privacy Policy, and BTC's operation and provision of Services, as contemplated under this Agreement, is not a breach of your right of privacy, publicity, or any other right related to protection of personal data, whether in equity, law, contract, or otherwise. You hereby waive any and all such claims or rights of action, whether foreseen or unforeseen, so long as your User Information is collected, stored, and used in accordance with BTC's Privacy Policy, or in the case of you making use of Materials or Services provided by BTC Associates, the privacy policy of such Associate.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>LINKS TO OTHER INTERNET WEB SITES.</span>This Site may contain links to third-party Web sites that are not under the control of BTC. BTC makes no representations whatsoever about any other Web site to which you may have access through this Site, including the Web sites of BTC Associates. When you access a non-BTC Web site, you do so at your own risk and BTC is not responsible for the accuracy or reliability of any information, data, opinions, advice, or statements made on these sites; or for the quality of any products or services available on such sites; or for any damage or loss caused or alleged to be caused through or in connection with use of or reliance on the content, goods, or services available on or through any such link, site, or resource. BTC provides these links merely as a convenience and the inclusion of such links does not imply that BTC endorses or accepts any responsibility for the content or uses of such Web sites.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>USER CONTENT.</span>You are solely responsible for the content of any data, information, questions, comments, feedback, ideas, descriptions of processes, or other information submitted to BTC through the Site. BTC does not want to receive confidential or proprietary information from you through the Site. Any non-personal unsolicited information or material sent to BTC will be deemed not confidential, and by sending such information you hereby grant BTC an unrestricted, irrevocable license to use, reproduce, display, perform, modify, transmit, and distribute those materials or information, and you also agree that BTC is free to use any ideas, concepts, know-how, or techniques that you send to us for any purpose. Personal information, such as names, address, telephone numbers, etc., will remain subject to BTC's Privacy Policy. For any information, materials, or documents of any kind submitted or placed on the Site ("User Content"), User shall obtain any and all licenses to, or releases of intellectual or proprietary rights in or to the User Content before delivery to the Site.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">Certain Services offered on the Site accommodate or require User Content. By submitting User Content to the Site, you will grant BTC a worldwide, royalty-free, non-exclusive license to reproduce and modify, for purposes of formatting, maintenance, or Site administration only, such User Content. This license will remain in effect until such User Content is removed from the Site. For User Content such as comments to the BTC user forums, bug reports, piracy reports, or product suggestions, you grant BTC an unrestricted, worldwide, irrevocable license to use, reproduce, display, perform, modify, transmit, and distribute such User Content, and you also agree that BTC is free to use any ideas, concepts, know-how, or techniques that you submit to the Site for any purpose. By virtue of these licenses, BTC only obtains the rights set out in the license grants and does not obtain any additional right, title, or interest to any copyright, patent, trademark, or other intellectual property right in or to the User Content. BTC may establish general practices and limits on the use of any Service, including, but not limited to, limits on retention time, file size, and storage space for User Content. BTC shall have no liability or responsibility for the deletion or failure to store any information or materials in connection with any Service.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">BTC reserves the right, at BTC's discretion, to refuse to post or require the removal of any User Content on the Site if BTC deems such User Content to be inappropriate.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>USER OBLIGATIONS AND WARRANTIES.</span>You may use the Site in accordance with this Agreement. You undertake and warrant that the use of the Site by you and the User Content you post on the Site, shall not: (i) be false, inaccurate or misleading, (ii) be fraudulent or involve the sale of counterfeit or stolen items (iii) be defamatory, libelous, obscene, excessively violent, scandalous, unlawfully threatening or unlawfully harassing, (iv) contain viruses or other contaminating or destructive features, (v) violate the personal, proprietary, or intellectual property rights of any third party, or (vi) violate any law, statute, ordinance or regulation (including, but not limited to, those governing import&amp;export control, consumer protection, unfair competition, anti-discrimination or false advertising).</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>WARRANTIES AND DISCLAIMERS.</span>EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN AGREEMENT SIGNED BY AUTHORIZED REPRESENTATIVES OF YOU AND BTC, ALL MATERIALS AND SERVICES ON THIS SITE ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, BTC MAKES NO WARRANTY THAT (i) THE SERVICES AND MATERIALS WILL MEET YOUR REQUIREMENTS, (ii) THE SERVICES AND MATERIALS WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR MATERIALS WILL BE EFFECTIVE, ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, OR INFORMATION PURCHASED OR OBTAINED BY YOU FROM THE SITE WILL MEET YOUR EXPECTATIONS.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">THIS SITE COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES OR TYPOGRAPHICAL ERRORS. BTC MAY MAKE CHANGES TO THE MATERIALS AND SERVICES AT THIS SITE, INCLUDING THE PRICES AND DESCRIPTIONS OF ANY SERVICES AND PRODUCTS LISTED HEREIN, AT ANY TIME WITHOUT NOTICE. THE MATERIALS OR SERVICES AT THIS SITE MAY BE OUT OF DATE, AND BTC MAKES NO COMMITMENT TO UPDATE SUCH MATERIALS OR SERVICES.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">BTC ASSUMES NO RESPONSIBILITY FOR ERRORS OR OMISSIONS IN THE MATERIALS AND/OR SERVICES OR FOR INFORMATION, MATERIALS, OR SERVICES OF THIRD PARTIES THAT ARE REFERENCED BY OR LINKED TO THIS SITE, INCLUDING THOSE OF BTC'S ASSOCIATES. REFERENCES TO OTHER COMPANIES, THEIR SERVICES AND PRODUCTS, ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. IN NO EVENT SHALL BTC OR ITS LICENSORS OR ASSOCIATES OR SUPPLIERS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF USE, DATA, OR PROFITS, WHETHER OR NOT BTC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THIS SITE OR OF ANY WEB SITE REFERENCED OR LINKED TO FROM THIS SITE, INCLUDING THOSE OF BTC'S ASSOCIATES. SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">THE USE OF THE SERVICES OR THE DOWNLOADING OR OTHER ACQUISITION OF ANY MATERIALS THROUGH THE SITE IS DONE AT YOUR OWN DISCRETION AND RISK, AND WITH YOUR AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM BTC OR THROUGH OR FROM THE SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS OF USE. IN ADDITION TO THE LIMITATIONS AND EXCLUSIONS SET FORTH ABOVE, BTC HAS NO LIABILITY FOR THE SUBJECT MATTER OF THIS AGREEMENT.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">USERS ACKNOWLEDGE THAT THE ALLOCATION OF RISK AND THE LIMITATIONS OF LIABILITIES CONTAINED IN THIS AGREEMENT IS A MATERIAL CONSIDERATION FOR BTC ENTERING INTO THIS AGREEMENT.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>INDEMNITY.</span>You shall, at your own expense, indemnify, defend, and hold BTC and its subsidiaries, affiliates, officers, employees, agents, co-branders, associates, suppliers, or other partners harmless from and against any losses, costs, damages, liabilities, and expenses (including reasonable attorneys' fees), arising out of or related to a third-party claim, action or allegation (a) related in any way to a transaction or dispute between you and another User or between you and any third party, (b) based on or caused by unauthorized access to the Site through User's account, (c) is based on facts or alleged facts that would constitute a breach of any of User's representations, warranties, or covenants under this Agreement, or (d) arising out of a request for BTC to remove or disable access to any User Content in or on the Site. You shall not enter into a settlement of the foregoing without BTC's prior written consent, and shall not incur expenses and fees in defending the above claims, actions, or allegations without prior approval from BTC.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">By using this site you acknowledge that, BTC has no control over the quality, safety, legality or accuracy of User Content, the ability of sellers to sell items or the ability of buyers to pay for items. BTC cannot ensure that a buyer or seller will actually complete a transaction. As a result, in the event that you have a dispute with one or more Users, you shall release BTC and its subsidiaries, affiliates, officers, employees, agents, co-branders, associates, suppliers, or other partners from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>ENTIRE AGREEMENT.</span>This Agreement constitutes the entire agreement between you and BTC with respect to the Site and supersedes all prior agreements or previous discussions (written or oral) between you and BTC, subject to any specific agreement governing Services subscribed by you through this Site. This Agreement may be modified only by a signed agreement, or as follows: BTC may modify this Agreement by posting the new terms and conditions in the same location the previous terms and conditions were posted. If you continue to use the Materials, Services, or Site, then you will be considered to have accepted the modifications.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>MISCELLANEOUS PROVISIONS.</span>BTC's failure to enforce any provision of this Agreement will not be construed as a waiver of any provision or right. In the event that a portion of this Agreement is held unenforceable, the unenforceable portion will be construed in accordance with applicable law as nearly as possible to reflect the original intentions of the parties, and the remainder of the provisions will remain in full force and effect. Waiver by either party of a breach of any provision of this Agreement or the failure by either party to exercise any right hereunder shall not operate or be construed as a waiver of any subsequent breach of that right or as a waiver of any other right.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">BTC will not be liable in any amount for failure to perform any obligation under this Agreement if such failure is caused by the occurrence of cause beyond the reasonable control of BTC including, without limitation, outages of the Internet or connections thereto, communications outages, earthquake, fire, flood, war, or act of God; or any acts of governmental bodies or authorities. The parties agree that this Agreement shall be written in English only.</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>GOVERNING LAW AND JURISDICTION.</span>This Site (excluding linked sites) is controlled by BEEF TO CHINA LIMITED. By accessing this Site, you and BTC agree that the statutes and laws of Hong Kong, China, without regard to the conflicts of laws principles thereof, shall govern all matters relating to your access to, or use of, this Site and any Materials or Services. You and BTC also agree and hereby submit to the exclusive personal jurisdiction and venue of the Courts of Hong Kong, China with respect to such matters. BTC makes no representation that Materials or Services available on this Site are appropriate or available for use in other locations, and accessing them from territories where their contents are illegal is prohibited. Those who choose to access this Site from other locations do so on their own initiative and are responsible for compliance with local laws.</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TermsConditions',
  components: {

  },
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.container-fluid{
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box-title{
  text-align:center;
  color: #333333;
  font-size: 20px;
  margin-top: 40px;
}
.divider{
  width: 100%;
  height: 2px;
  background-color: #F5F5F5;
  margin-top: 20px;
}

.box-body{
  margin-bottom: 20px;
}
.box-body div{
  margin: 20px 0;
  font-size: 14px;
  color: #666666;
}
.box-body div span{
  font-size: 16px;
}
</style>
